"use strict";
// Distribute
var creative1;
var creative1Name;
var creative1OwnerName;
var creative2;
var creative2Name;
var creative2Owner;
var grid;
var statesGrid;
var shareGrid;
var shareID;
var SlideDownDurationDS = 600;
var ScrollAnimationTimeDS = 800;
var DemoAccountIDDS = 1344;
cellClassRules = { "cell-pos-green": function (params) { return params.value >= 0; }, "cell-neg-red": function (params) { return params.value < 0; } };
$(function () {
    $('#share_btn,#del_share_btn').hide(0);
    clearDropdowns();
    if (config.g || config.aid == DemoAccountIDDS) {
        $('#rename_btn').hide(0);
    }
    $('#share_error').css('white-space', 'pre-line');
    clickDS('#rename_btn', renameDS);
    //clickDS('#share_btn', showSharing);
    //clickDS('#del_share_btn', deleteShare);
    //clickDS('#share_close', hideSharing);
    //clickDS('#share_submit', createShare);
    //$('#share_email,#share_days').on('focus', clearSignErrDS);
});
function clickDS(selector, f) {
    $(selector).on('click', function (e) {
        e.preventDefault();
        f();
    });
}
function clearSignErrDS() {
    $('#share_error').parent().hide();
}
function scrollIntoViewDS(element, callback) {
    var _a;
    $('html, body').animate({ scrollTop: (_a = element.offset()) === null || _a === void 0 ? void 0 : _a.top }, ScrollAnimationTimeDS, '', callback);
}
function showSharing() {
    var shareFormSelector = '#share_form';
    if (!$(shareFormSelector).is(':visible')) {
        $('#share_btn').hide();
        $(shareFormSelector).slideDown(SlideDownDurationDS);
        scrollIntoViewDS($(shareFormSelector).parent());
    }
}
function hideSharing() {
    var shareFormSelector = '#share_form';
    if ($(shareFormSelector).is(':visible')) {
        clearSignErrDS();
        $(shareFormSelector).slideUp(SlideDownDurationDS, '', function () {
            scrollIntoViewDS($('#list_div').parent(), gridRowClickDS);
        });
    }
}
function gridLoadedDS() {
    updateFields();
    var buttonCSS = { opacity: 0 };
    if (grid && grid.api.getDisplayedRowCount() > 1) {
        buttonCSS = { opacity: 1 };
        var row = grid.api.getDisplayedRowAtIndex(0);
        creative1 = row.data.A;
        creative1Name = row.data.B;
        creative1OwnerName = row.data.C;
        row = grid.api.getDisplayedRowAtIndex(1);
        creative2 = row.data.A;
        creative2Name = row.data.B;
        creative2Owner = row.data.C;
        $('#c1_map_label').text(creative1Name);
        $('#c2_map_label').text(creative2Name);
        fetchCompareData();
        createDropdowns();
    }
    else {
        clearDropdowns();
    }
    $('#compare_tab_btn').css(buttonCSS);
}
function createDropdowns() {
    $('#primary_val').text(creative1Name);
    $('#secondary_val').text(creative2Name);
    $('#co_name_1').text(creative1OwnerName);
    $('#co_name_2').text(creative2Owner);
    addDropdowns();
}
function clearDropdowns() {
    $('#primary_val').text('');
    $('#secondary_val').text('');
    var primarySelect = $('#primary_select');
    var secondarySelect = $('#secondary_select');
    primarySelect.empty();
    secondarySelect.empty();
}
var SelectItemTemplate = '<a href="#" onclick="%%method%%" class="compare-dropdown-link w-dropdown-link" tabindex="0">%%value%%</a>';
function addDropdowns() {
    var primarySelect = $('#primary_select');
    var secondarySelect = $('#secondary_select');
    primarySelect.empty();
    secondarySelect.empty();
    grid.api.forEachNode(function (node) {
        var id = node.data.A;
        var name = node.data.B;
        var owner = node.data.C;
        var itemTemplate = SelectItemTemplate.replace('%%value%%', name);
        primarySelect.append(itemTemplate.replace('%%method%%', 'primaryClick(\'' + id + '\',\'' + name + '\',\'' + owner + '\')'));
        secondarySelect.append(itemTemplate.replace('%%method%%', 'secondaryClick(\'' + id + '\',\'' + name + '\',\'' + owner + '\')'));
    });
}
function primaryClick(creative, creativeName, creativeOwner) {
    creative1 = creative;
    creative1Name = creativeName;
    creative1OwnerName = creativeOwner;
    $('#primary_val').text(creative1Name);
    $('#co_name_1').text(creative1OwnerName);
    $('#c1_map_label').text(creative1Name);
    fetchCompareData();
    $('#primary_select').removeClass('w--open');
}
function secondaryClick(creative, creativeName, creativeOwner) {
    creative2 = creative;
    creative2Name = creativeName;
    creative2Owner = creativeOwner;
    $('#secondary_val').text(creative2Name);
    $('#co_name_2').text(creative2Owner);
    $('#c2_map_label').text(creative2Name);
    fetchCompareData();
    $('#secondary_select').removeClass('w--open');
}
function gridRowClickDS() {
    var cell = grid.api.getFocusedCell();
    if (cell) {
        var row = grid.api.getDisplayedRowAtIndex(cell.rowIndex);
        setConfig('creative', row.data.A);
        setConfig('creativeDetail', '');
        row.setSelected(true, true);
        updateFields();
    }
    hideSharing();
}
function updateFields() {
    $('#share_btn').hide();
    var row;
    var rowIndex = 0;
    var cell = grid.api.getFocusedCell();
    if (cell) {
        rowIndex = cell.rowIndex;
    }
    else {
        if (!config.creative) {
            row = grid.api.getDisplayedRowAtIndex(0);
            setConfig('creative', row.data.A);
        }
        if (config.creative) {
            grid.api.forEachNode(function (row) {
                if (config.creative == row.data.A) {
                    rowIndex = row.rowIndex;
                }
            });
        }
    }
    row = grid.api.getDisplayedRowAtIndex(rowIndex);
    $('#desc_field').text(row.data.B);
    $('#date_field').text(row.data.F);
    $('#records_field').text(row.data.G);
    $('#status_field').text(row.data.H);
    // let isOwner = (row.data.D == 1);
    // if (isOwner && !config.g && config.aid != DemoAccountIDDS) {
    //   $('#share_btn').css('display', 'inline-block');
    // }
    row.setSelected(true, true);
}
function fetchListsDS() {
    if (config.aid) {
        $("#list_div,#shares_div").empty();
        var req = { aid: config.aid, auid: config.auid };
        grid = fetchList(req, demographicsServiceURL + '/list', 'creatives', 'list_div', [], [6], [], gridRowClickDS);
        grid.onFirstDataRendered = gridLoadedDS;
        shareGrid = fetchList(req, ioServiceURL + '/list', 'shares', 'shares_div', [], [], [], shareRowClick);
        shareGrid.onFirstDataRendered = shareGridLoaded;
    }
}
function fetchCompareData() {
    fetchCompareCharts();
    fetchCompareMaps();
    fetchCompareLists();
}
function fetchCompareCharts() {
    if (config.aid) {
        var url = compareServiceURL + '/graph';
        var req = { aid: config.aid, auid: config.auid, creative: creative1, creative2: creative2 };
        var defs = columnChartDefaultOptions, legDefs = { legend: { position: 'none' } };
        fetchChart(req, url, 'age', ['compare_tab', 'demo_tab'], 'age_chart', ChartType.Column, defs, { hAxis: { format: '##s' }, legend: { position: 'none' } });
        fetchChart(req, url, 'gender', ['compare_tab', 'demo_tab'], 'gender_chart', ChartType.Column, defs, legDefs);
        fetchChart(req, url, 'income', ['compare_tab', 'demo_tab'], 'income_chart', ChartType.Column, defs, legDefs);
        fetchChart(req, url, 'networth', ['compare_tab', 'demo_tab'], 'networth_chart', ChartType.Column, defs, legDefs);
        fetchChart(req, url, 'marital_status', ['compare_tab', 'demo_tab'], 'marital_status_chart', ChartType.Column, defs, legDefs);
        fetchChart(req, url, 'children', ['compare_tab', 'demo_tab'], 'children_chart', ChartType.Column, defs, legDefs);
        fetchChart(req, url, 'ownership', ['compare_tab', 'demo_tab'], 'ownership_chart', ChartType.Column, defs, legDefs);
        fetchChart(req, url, 'marketvalue', ['compare_tab', 'demo_tab'], 'homevalue_chart', ChartType.Column, defs, {
            hAxis: { slantedText: true, slantedTextAngle: 45 }, legend: { position: 'none' }
        });
        fetchChart(req, audienceServiceURL + '/audienceChart', 'audienceChart', ['compare_tab', 'audience_tab'], 'audience_chart', ChartType.Bar, barChartDefaultOptions, {
            width: 1200, height: 1100, colors: standardColors, legend: { position: 'none' }, hAxis: { textPosition: 'none' }, vAxis: { format: 'none' }
        });
        // Geo charts
        url = demographicsServiceURL + '/graph';
        defs = { region: 'US', resolution: 'provinces', colorAxis: { colors: ['#eef', standardColors[0]] } };
        req = { aid: config.aid, auid: config.auid, creative: creative1 };
        fetchChart(req, url, 'state_count_mrkt', ['map_tab', 'geo_by_state'], 'state_c1_chart', ChartType.Geo, defs, {});
        req = { aid: config.aid, auid: config.auid, creative: creative2 };
        fetchChart(req, url, 'state_count_mrkt', ['map_tab', 'geo_by_state'], 'state_c2_chart', ChartType.Geo, defs, {});
    }
}
function fetchMaps() { }
function fetchCompareMaps() {
    var zoom = 4;
    var req = { aid: config.aid, auid: config.auid, creative: creative1, creative2: creative2 };
    fetchMap(req, compareServiceURL + '/map', 'map_div', 'map_tab', zoom);
}
function fetchCompareLists() {
    var req = { aid: config.aid, auid: config.auid, creative: creative1, creative2: creative2, resultType: 'comp' };
    $("#state_list").empty();
    statesGrid = fetchList(req, demographicsServiceURL + '/list', 'stateCounts', 'state_list', [], [], [1, 2], null, [1, 2]);
}
function createShare() {
    try {
        if (config.aid) {
            $('#share_submit,#share_close').prop('disabled', true);
            var formData = { aid: config.aid, auid: config.auid, creative: config.creative, email: $('#share_email').val(), expirationDays: $('#share_days').val() };
            $.ajax({
                type: "POST",
                url: ioServiceURL + '/shareCreative',
                data: JSON.stringify(formData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function (data) {
                    if (data.success) {
                        $('#share_email,#share_days').val('');
                        hideSharing();
                        fetchListsDS();
                    }
                    else {
                        $('#share_error').text(data.errors);
                        $('#share_error').parent().show();
                    }
                    $('#share_submit,#share_close').prop('disabled', false);
                }
            });
        }
    }
    catch (e) {
        console.log('Error: ' + e);
        $('#share_submit,#share_close').prop('disabled', false);
    }
}
function shareGridLoaded() {
    if (shareGrid.api.getDisplayedRowCount() > 0) {
        var row = shareGrid.api.getDisplayedRowAtIndex(0);
        row.setSelected(true, true);
    }
    shareRowClick();
}
function shareRowClick() {
    $('#del_share_btn').hide();
    var cell = shareGrid.api.getFocusedCell();
    var row;
    if (cell) {
        row = shareGrid.api.getDisplayedRowAtIndex(cell.rowIndex);
        row.setSelected(true, true);
    }
    if (shareGrid.api.getDisplayedRowCount() > 0) {
        if (!row)
            row = shareGrid.api.getDisplayedRowAtIndex(0);
        shareID = row.data.A;
        if (!config.g) {
            $('#del_share_btn').show();
        }
    }
}
function deleteShare() {
    try {
        if (config.aid) {
            $('#del_share_btn').prop('disabled', true);
            var formData = { aid: config.aid, auid: config.auid, share: shareID };
            $.ajax({
                type: "POST",
                url: ioServiceURL + '/deleteShare',
                data: JSON.stringify(formData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function (data) {
                    if (data.success)
                        fetchListsDS();
                }
            });
        }
    }
    catch (e) {
        console.log('Error: ' + e);
        $('#del_share_btn').prop('disabled', false);
    }
}
function renameDS() {
    try {
        var currentName = $('#desc_field').text();
        var newName = prompt("Enter new name", currentName);
        if (!newName || newName == currentName)
            return;
        if (config.aid) {
            $('#rename_btn').prop('disabled', true);
            var formData = { aid: config.aid, auid: config.auid, creative: config.creative, name: newName };
            $.ajax({
                type: "POST",
                url: demographicsServiceURL + '/renameCreative',
                data: JSON.stringify(formData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function () {
                    fetchListsDS();
                }
            });
        }
    }
    catch (e) {
        console.log('Error: ' + e);
        $('#rename_btn').prop('disabled', false);
    }
}
